import axios from 'axios'  
import { Message } from 'element-ui';
import router from '../router'
//请求拦截器
// axios.interceptors.request.use(config=>{
//     //如果存在token，则请求携带这token
//     if(window.sessionStorage.getItem('Authorization')){
//         config.headers['Authorization'] = 
// 		window.sessionStorage.getItem('Authorization');
//     }
//     return config;
// },error => {
//     console.log(error);
// })
// 获取 sessionStorage 中的用户信息
const userInfo = sessionStorage.getItem('user');
//响应拦截器
axios.interceptors.response.use(success=>{
    //返回状态码为200
    if(success.status){
        if(success.data.code==500 
		|| success.data.code==401 
		|| success.data.code==403 || success.data.code==201){
            Message.error({message:success.data.message});
            return;
        }
		if(success.data.code==200){
			Message.success({message:success.data.message});
		}
    }
    return success.data;
},error => {
    if(error.response.code==504 || error.response.code==404){
        Message.error({message:'服务器被吃了(⊙﹏⊙)'});
    }else if(error.response.code==403){
        Message.error({message:'权限不足，请联系管理员！'});
    }else if(error.response.code==401){
        Message.error({message:'尚未登陆，请登录！'});
        router.replace('/');
    }else{
        if(error.response.data.message){
            Message.error({message:error.response.data.message});
        }else{
            Message.error({message:'未知错误！'});
        }
    }
    return;
});

// 作用  /user/userList
let base = '';
//传送json格式的post请求
export const postRequest =(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params,
    })
}

//传送json格式的put请求
export const putRequest = (url,params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
    })
}

//传送json格式的get请求
export  const getRequest = (url,params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        data: params,
    })
}

//传送json格式的delete请求
export const deleteRequest = (url,params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params,
    })
}
