import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;
Vue.use(ElementUI,{size:"small"});
Vue.prototype.$axios=axios;
// 初始化我们的菜单/权限
import {initMenu} from './utils/menus'

// 引入封装的请求工具类
import {postRequest} from "./utils/api";
import {putRequest} from "./utils/api";
import {getRequest} from "./utils/api";
import {deleteRequest} from "./utils/api";
// 注册全局变量
Vue.prototype.$postRequest = postRequest;
Vue.prototype.$putRequest = putRequest;
Vue.prototype.$getRequest = getRequest;
Vue.prototype.$deleteRequest = deleteRequest;

Vue.config.productionTip = false
router.beforeEach((to,from,next)=>{
	initMenu(router,store);
	next();
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
