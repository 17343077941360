// 获取当前用户的菜单信息,封装为一个工具类
import {postRequest} from './api'
//导出
export const initMenu = (router,store) =>{
  console.log('初始化权限信息,initMenu')
  if(store.state.routes.length > 0){
    return;
  }
  //没有数据,获取数据库中的数据
  if(sessionStorage.getItem("user")){
	  let userObj  =JSON.parse(sessionStorage.getItem("user"));
	  postRequest("/menu/menuListByUserId/"+userObj.id,"").then(resp=>{
	    if(resp){
	      //格式化Router
	      let fmtRoutes = formatRoutes(resp.data.menus);
	      //添加到路由
	  	  router.addRoutes(fmtRoutes);
	  	  console.log("======="+JSON.stringify(fmtRoutes));
	      //将数据存入vuex中
	      store.commit('initRouters',fmtRoutes);
	    }
	  })
  }
}
export const formatRoutes = (routes) =>{
  let fmtRoutes = [];
  routes.forEach(router => {
    let{
	  id,
      path,
      component,
      name,
      iconCls,
      children
    } = router;
    if(children && children instanceof Array){
      //递归
      children = formatRoutes(children);
    }
    let fmRouter = {
	  id:id,
      path: path,
      name: name,
      iconCls: iconCls,
      children: children,
      component(resolve){
		  if(component.startsWith("Main")){
			   require(['@/views/Main.vue'],resolve); 
		  }else{
			  require(['@/components'+component+'.vue'],resolve); 
		  }
      }
    }
    fmtRoutes.push(fmRouter);
  });
  return fmtRoutes;
}
