import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  routes:[]
  },
  getters: {
  },
  mutations: {
	  initRouters(state,menus){
	  		  state.routes=menus
	  }
  },
  actions: {
  },
  modules: {
  }
})
