import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import("../views/Login.vue")
  },
  {
	  path:"/userLogin",
	  name:"login",
	  component:()=>import("../components/user/Login.vue")
  },
  {
	  path:"/UserMain",
	  name:"main",
	  component:()=>import("../components/user/UserMain.vue"),
	  children:[{
		  path:"person",
		  name:"个人信息修改",
		  component:()=>import("../components/user/person.vue"),
	  },
	  {
	  		  path:"leave",
	  		  name:"请假管理",
	  		  component:()=>import("../components/user/Leave.vue"),
	  },
	  {
	  		  path:"personnel",
	  		  name:"外勤管理",
	  		  component:()=>import("../components/user/Personnel.vue"),
	  }
	  ]
  }
]

const router = new VueRouter({
  routes
})

export default router
